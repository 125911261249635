<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
                        <b-form-group label="Username" label-for="username" class="mb-0">
                            <b-form-input id="username" v-model="search.username"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
                        <b-form-group label="Bank Account" label-for="bankAccount" class="mb-0">
                            <b-form-input id="bankAccount" v-model="search.bankAccount"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="d-flex align-items-end justify-content-end">
                    <b-col cols="12" md="4" class="d-flex align-items-end justify-content-end">
                        <b-button variant="outline-primary" @click="resetFilter()">
                            Reset
                        </b-button>
                        <b-button variant="primary" class="ml-2" @click="searchFilter()">
                            Search
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card no-body>
            <b-table ref="refAuditBankAccountTable" class="position-relative table-white-space mb-0 max-height-table"
                sticky-header head-variant="light" :items="auditBankAccountList" responsive :fields="tableColumns" primary-key="index"
                show-empty empty-text="No matching records found">
                <template #cell(index)="data">
                    {{ perPage * (currentPage - 1) + data.index + 1 }}
                </template>

            </b-table>
            <div class="p-2">
                <b-row>
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                            {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              ">
                        <b-pagination v-model="currentPage" :total-rows="totalAuditBankAccount" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!-- Edit User Bonus Request Modal -->
        <edit-user-log-kyc-modal :user-kyc-log.sync="userLogKyc" @refetch-data="refetchData" />
    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg, VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import flatPickr from "vue-flatpickr-component";
import userStoreModule from "@/views/apps/user/userStoreModule";
import EditUserLogKycModal from "@/views/apps/user/users-kyc/EditUserLogKycModal";
import moment from "moment";

export default {
    components: {
        EditUserLogKycModal,
        flatPickr,
        BButton,
        BFormGroup,
        BModal,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BTr,
        BTh,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BImg,
    },
    directives: {
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            userLogKyc: null,
        };
    },
    methods: {
    },
    setup() {
        if (!store.hasModule("user"))
            store.registerModule("user", userStoreModule);
        onUnmounted(() => {
            if (store.hasModule("user")) store.unregisterModule("user");
        });

        const search = ref({
            username: "",
            bankAccount: "",
        });
        const usernameFilter = ref(null);
        const sportTypeId = ref([]);
        const auditBankAccountList = ref([]);
        const perPage = ref(25);
        const totalAuditBankAccount = ref(0);
        const currentPage = ref(1);

        const refAuditBankAccountTable = ref(null);
        const tableColumns = [
            { key: "index", label: "#", sortable: false },
            { key: "bank_account", label: "Bank Account" },
            { key: "count", label: "Count" },
            { key: "user_list", label: "User List" },
        ];

        const refetchData = () => {
            refAuditBankAccountTable.value.refresh();
            sportTeamLists();
        };

        watch(
            [currentPage],
            () => {
                refetchData();
            }
        );
        const transformedArray = ref();
        const sportTeamLists = (ctx, callback) => {
            store
                .dispatch("user/fetchAuditBankAccount", {
                    username: search.value.username,
                    bankAccount: search.value.bankAccount,
                    pageNumber: currentPage.value,
                })
                .then((response) => {
                    auditBankAccountList.value = response.data.data;
                    totalAuditBankAccount.value = response.data.count;
                });
        };

        sportTeamLists();

        const dataMeta = computed(() => {
            const localItemsCount = refAuditBankAccountTable.value
                ? refAuditBankAccountTable.value.localItems.length
                : 0;
            return {
                from:
                    perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totalAuditBankAccount.value,
            };
        });

        const resetFilter = () => {
            search.value.username = "";
            search.value.bankAccount = "";
            sportTeamLists();
        };

        const searchFilter = () => {
            sportTeamLists();
        };

        return {
            auditBankAccountList,
            tableColumns,
            perPage,
            currentPage,
            totalAuditBankAccount,
            dataMeta,
            refAuditBankAccountTable,
            sportTeamLists,
            refetchData,
            search,
            resetFilter,
            numberFormat,
            formatDateTime,
            searchFilter,
            baseUrl,
            sportTypeId,
            transformedArray,
        };
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  